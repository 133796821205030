import React from "react"
import { graphql } from "gatsby"
import SteppedCompatrix from "../components/steppedCompatrix"

export default function Template({ data }) {
  return <SteppedCompatrix steps={data.compatrixSteppedJson.steps} />
}

export const compatrixQuery = graphql`
  query($id: String!) {
    compatrixSteppedJson(id: { eq: $id }) {
      steps {
        tickLabel
        description
        data {
          ruleset
          target {
            name
            versions
          }
          dependers {
            name
            versions {
              version
              inputs {
                use
                min
                incompats
                pincompats
                scouted
              }
            }
          }
          highlights {
            depender
            pairs
          }
        }
      }
    }
  }
`
