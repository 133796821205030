import React from "react"
//import PropTypes from "prop-types"
import BasicCompatrix from "./basicCompatrix"
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider"
import { MuiRail, MuiHandle, MuiTrack, MuiTick } from "./slider-components"
import "./compatrix.css"

class SteppedCompatrix extends React.PureComponent {
  state = {
    selected: [0],
  }

  onChange = selected => {
    this.setState({ selected })
  }

  render() {
    const step = this.props.steps[this.state.selected[0]]

    const sliderStyle = {
      position: "relative",
      width: "100%",
    }

    const domain = [0, this.props.steps.length - 1]
    const tickValues = []
    for (let i = 0; i < this.props.steps.length; i++) {
      tickValues.push(i)
    }

    const formatTick = val => {
      return this.props.steps[val].tickLabel
    }

    return (
      <div className="compatrix-outer">
        <BasicCompatrix
          ruleset={step.data.ruleset}
          target={step.data.target}
          depender={step.data.dependers[0]}
          cheats={step.data.cheats}
          highlights={step.data.highlights}
        />
        <p dangerouslySetInnerHTML={{ __html: step.description }}></p>
        <Slider
          rootStyle={sliderStyle}
          mode={1}
          step={1}
          domain={domain}
          values={this.state.selected}
          onChange={this.onChange}
        >
          <Rail>
            {({ getRailProps }) => <MuiRail getRailProps={getRailProps} />}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map(handle => (
                  <MuiHandle
                    key={handle.id}
                    handle={handle}
                    domain={domain}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <MuiTrack
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
          <Ticks values={tickValues}>
            {({ ticks }) => (
              <div className="slider-ticks">
                {ticks.map(tick => (
                  <MuiTick
                    key={tick.id}
                    tick={tick}
                    count={ticks.length}
                    format={formatTick}
                  />
                ))}
              </div>
            )}
          </Ticks>
        </Slider>
      </div>
    )
  }
}

// PureSVGCompatrix.propTypes = {
//target: PropTypes.object.isRequired,
//dependers: PropTypes.object.isRequired,
//cheats: PropTypes.arrayOf(Proptypes.object),
//}

export default SteppedCompatrix
